import { Directive } from '@angular/core';

@Directive({
    selector: '[ins-readMore]',
    exportAs: 'ins-readMore',
})
export class ReadMoreDirective {
    public visible: boolean = false;

    public toggle(event): void {
        event.preventDefault();
        this.visible = !this.visible;
    }
}
